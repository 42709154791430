import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPoundSign } from '@fortawesome/free-solid-svg-icons'
import EditButton from './EditButton'
import VenueName from './VenueName'
import SchedulePopover from './Popover'
import { useRef, useState } from 'react'
import PublicButton from '../PublicButton'

export const EventCell = props => {
  const {
    id,
    title,
    name,
    performer,
    __type,
    venue,
    start,
    className = '',
    colspan,
    periodStart,
    venueStart,
    offerings,
    tags
  } = props

  const timeString = start.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })

  const icons = []

  const [popoverVisible, setPopoverVisible] = useState(false)
  const target = useRef(null)

  icons.push(
    <PublicButton
      id={id}
      key="publicButton"
    />
  )

  icons.push(
    <EditButton
      key="editButton"
      id={id}
      type={__type}
      mode="icon"
      className="ms-auto text-gray-600"
    />
  )

  const tagClass = tags && tags.length ? tags[0].toLowerCase() : ''

  const tagElement =
    tags && tags.length ? <p className="m-0 mb-1 fst-italic text-small text-gray-600">{tags.join(', ')}</p> : null

  const performerElement =
    performer && performer.name && performer.name !== (name || title) ? (
      <p
        className="m-0 text-small
    m-0 fw-bold text-gray-600">
        {performer.name}
      </p>
    ) : null

  return (
    <td
      colSpan={colspan}
      className={`${className} ${periodStart ? 'border-start-gray-700 ' : ''} ${
        venueStart ? 'border-top-gray-700 ' : ''
      } h-100 bg-gray-800 border-end-dark border-bottom-dark cursor-pointer ${tagClass}`}
      onClick={() => setPopoverVisible(true)}
      ref={target}>
      <div className="p-2 w-100 h-100 tagged flex-column d-flex justify-content-between">
        <div>
          <h6 className="m-0 fw-normal">{name || title}</h6>
          {performerElement}
          <p className="m-0 text-small text-gray-400">
            {timeString}{' '}
            <span className="fw-bold text-gray-600">
              <VenueName name={venue && venue.id ? venue.id : null} />
            </span>
            {offerings && offerings.filter(offering => offering.active).length ? (
              <FontAwesomeIcon
                key="ticketIcon"
                icon={faPoundSign}
                className="text-warning ms-1"
              />
            ) : null}
          </p>
          {tagElement}
        </div>
        <div>
          <p className="m-0 d-flex justify-content-between align-items-end">{icons}</p>
        </div>
        <SchedulePopover
          resource={__type}
          id={id}
          show={popoverVisible}
          target={target}
          handleClose={e => {
            e.stopPropagation()
            setPopoverVisible(false)
          }}
        />
      </div>
    </td>
  )
}

export default EventCell
