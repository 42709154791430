import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import { useDispatch, useSelector } from 'react-redux'
import { patchEvent, fetchEvent, reloadEventFeed } from '../../redux/event/actions'
import { getEvent, isEventAdmin } from '../../redux/state'
import { eventIsLoaded } from '../../redux/event/state'

export const PublicButton = props => {
  const dispatch = useDispatch()

  const { id, handleSaveSuccess: onSuccess, handleSaveError: onError, className = '' } = props

  const data = useSelector(state => getEvent(state, id))
  const isAdmin = useSelector(state => isEventAdmin(state, id))

  const dataLoaded = useSelector(state => eventIsLoaded(state, id))

  const [isPublic, setPublic] = useState(false)

  useEffect(() => {
    if (id && id !== 'new') {
      dispatch(fetchEvent(id, true))
    }
  }, [])

  useEffect(() => {
    if (data) {
      setPublic(data.visibility === 'public')
    }
  }, [data])

  const handleChange = event => {
    let { checked } = event.target

    setPublic(checked)
    saveData({ id, name: data.name, visibility: checked ? 'public' : 'private' })
  }

  const saveData = update => {
    // Submit patch request to update event visibility
    return dispatch(patchEvent(update))
      .then(result => {
        handleSaveSuccess(result)
      })
      .catch(err => {
        handleSaveError(err.message)
      })
  }

  const handleSaveSuccess = result => {
    // dispatch(reloadEventFeed(data.id))
    if (onSuccess) {
      onSuccess(result)
    }
  }

  const handleSaveError = err => {
    if (onError) {
      onError(err)
    }
  }

  if (!dataLoaded || !isAdmin) {
    return null
  }

  return (
    <Form className={`text-small text-gray-${isPublic ? '400' : '600'} d-inline m-0 p-0 ${className}`}>
      <Form.Check
        className="m-0"
        type="switch"
        size={'sm'}
        checked={isPublic}
        id={`public-switch-${id}`}
        label="Public"
        name="public"
        onClick={e => e.stopPropagation()}
        onChange={handleChange}
      />
    </Form>
  )
}

export default PublicButton
