import { Fragment } from 'react'

const ListContent = props => {
  const { logo, caption } = props
  return (
    <Fragment>
      {logo ? (
        <img
          src={logo}
          alt={caption || ''}
          className="sponsors__image mw-100 border-0"
        />
      ) : null}
      {caption ? <p className="sponsors__caption my-3 fst-italic">{caption}</p> : null}
    </Fragment>
  )
}

const ListItem = props => {
  const { link } = props
  const content = <ListContent {...props} />
  if (!link) {
    return content
  }
  return (
    <a
      href={link}
      className="sponsors__link text-decoration-none text-black d-block text-center">
      {content}
    </a>
  )
}

export default props => {
  const { data, className } = props

  if (!data || !data.length) {
    return null
  }

  return (
    <ul
      className={`list-unstyled ${className} sponsors__list d-flex justify-content-center align-items-center flex-wrap`}>
      {data.map((sponsor, index) => (
        <li
          key={index}
          className="sponsors__list_item text-center px-3 px-md-4 px-lg-5 py-3 col-6 col-md-4 col-lg-3">
          <ListItem {...sponsor} />
        </li>
      ))}
    </ul>
  )
}
